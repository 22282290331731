html, body, #root {
  height: 100%;
  margin: 0;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.services {
  margin-bottom: 1.5em;
}

#navlinks a {
  font-size: 0.85em;
  color: #ad0002;
}

/*
color="#ad0002"
*/

/* .container {
  display: flex;
  flex-direction: column;
  padding-bottom: 2.5em;
}

.sections {
  flex-grow: 1;
  text-align: top;
}

.sections p {
  text-align: top;
  padding: none;
  align-self: flex-start;
} */